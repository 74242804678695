<template>
  <div class="record">
    <div class="border">
      <el-table
        class="customer-no-border-table"
        :data="list"
        :header-cell-style="headerCellStyle"
        :cell-style="cellStyle"
      >
        <!-- ::row-style="showRow" -->
        <!-- :row-class-name="tableRowSetting" -->

        <!-- :header-cell-style="headerCellStyle" -->
        <el-table-column prop="operContent" label="操作内容"> </el-table-column>
        <el-table-column prop="operateTime" label="时间">
          <template slot-scope="scope">{{
            scope.row.operateTime | formatCreateTime
          }}</template>
        </el-table-column>
        <el-table-column prop="operateName" label="操作人"> </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.pageNum"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { formatDate } from "../../../utils/date";
import { getBehaviorList } from "../../../api/journal";
const defaultListQuery = {
  pageNum: 1,
  pageSize: 8,
  behaviorType: 3, //行为类型（1:登录,2:网站浏览,3:管理操作）
  operContent: "", //内容信息
  operateBy: null, //操作人
  operModule: "", //操作模块
  operateTimeEnd: null, //结束时间
  operateTimeStart: null, //开始时间
  userType: 1, //用户类型
};
export default {
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      listLoading: true,
      list: [], //日志列表数据
      total: null, //总条数
      userList: [], //用户列表
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/record", title: "操作记录" },
      ],
    };
  },
  created() {
    // 获取操作日志
    this.getList();
  },
  filters: {
    // 时间过滤器
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  methods: {
    //获取日志列表
    getList() {
      this.listLoading = true;
      getBehaviorList(this.listQuery).then((res) => {
        console.log(res);
        this.listLoading = false;
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
    //分页查询
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getList();
    },
    //指定页码查询
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getList();
    },
    // 表头样式
    headerCellStyle() {
      return "background-color:#F7F7F7;color:#333333;font-size:14px;height:45px;text-align:center;font-weight: 400;";
    },
    cellStyle(column) {
      if (column.columnIndex == 0) {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;";
      } else if (column.columnIndex == 1) {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;";
      } else {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;";
      }
    },
  },
  components: {},
};
</script>

<style lang="scss" socped>
.record {
  margin-top: 20px;
  .el-breadcrumb__item {
    height: 39px;
    line-height: 39px;
    // padding-top: 10px;
  }

  ::v-deep .el-tabs__content {
    height: 602px;
    // background-color: #4cb2fc;s
  }

  ::v-deep .el-tabs__item {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    // color: #0294ff;
    line-height: 60px;
    height: 60px;
  }

  .el-pagination {
    display: flex;
    height: 74px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
  }

  .el-table {
    width: 970px;
    height: 565px;
    margin-bottom: 0px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }

  .el-table__header {
    border-top: 1px solid #e5e5e5;
  }

  .el-table__row {
    height: 60px !important;
  }

  .border {
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    height: 663px;
    margin-bottom: 15px;
  }
}
</style>
